@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.bg-menu {
  background-color: #01584c !important;
}
.slider {
  width: 100%;
  height: 50% !important;
  opacity: 0.6;
  background-color: black !important;
}
.bg-caption {
  border-radius: 10px;
  color: black !important;
  width: 40%;
  top: 40%;
  bottom: 20% !important;
  text-align: start !important;
  padding: 10px 20px;
}
.btn-medicoll {
  background-color: #01584c !important;
  color: white !important;
  border: none;
}
.bg-bar {
  background-color: #037c6c !important;
  color: #fff;
}
.bg-footer {
  background-color: #01584c !important;
}
.footer {
  color: #dcdcdc !important;
}
.footer h4 {
  color: white !important;
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-style: dotted;
}
.heading {
  text-decoration: underline;
  text-decoration-color: red;
  text-decoration-style: dotted;
}
.bg-abt {
  background-color: #dce8e8 !important;
}
.bg-team {
  background-color: #f0fffc !important;
}
.bg-director {
  background-color: #b3f7ea !important;
}
.bg-mission {
  background-color: rgba(1, 88, 76, 0.05);
}
.about {
  position: relative;
}
.about h3 {
  position: absolute;
  top: 60%;
}
.abt-head {
  color: #01584c !important;
}
.history {
  margin-top: -25px !important;
}
.f-link {
  color: #dcdcdc !important;
}
.f-link:hover {
  color: white !important;
}
.bg-stores {
  background-image: linear-gradient(#9ac6e1, #5f90b1);
  align-items: center;
  text-align: center;
  padding: 50px 0;
}

@media screen and (max-width: 992px) {
  .m-email {
    display: none !important;
  }
}
